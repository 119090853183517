import { extendTheme } from '@chakra-ui/react';
import borderRadius from './border_radius';
// import buttonComponent from './button';
import inputComponent from './input';
import colors from './colors';
import dimensions from './dimensions';
import sizes from './sizes';
import shadows from './shadows';

const theme = extendTheme({
  borderRadius,
  components: {
    // Button: buttonComponent,
    Input: inputComponent,
    Textarea: {
      baseStyle: {
        borderWidth: '0.063rem',
        borderColor: '#979699',
      },
    },
    Link: {
      baseStyle: {
        textDecoration: 'none',
      },
    },
  },
  colors,
  fonts: {
    heading: `"DM Sans", sans-serif`,
    body: `"Plus Jakarta Sans", sans-serif`,
    mono: `'Poppins', sans-serif`,
  },
  fontSizes: dimensions,
  space: dimensions,
  sizes,
  shadows,
});

export default theme;
