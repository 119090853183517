import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { NumberOfPromoState } from '../promotion_types';
import { PromotionRepository } from '../repositories/promotion.repo';
import { PromotionService } from '../services/promotion.service';

const NAMESPACE = 'TOTAL_NUMBER_OF_PROMO';

const initialState: NumberOfPromoState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalPromotions: 0 },
};

export const totalNumberOfPromo = createAsyncThunk(`${NAMESPACE}/promo`, async () => {
  const promotionRepo = new PromotionRepository();
  const promotionService = new PromotionService(promotionRepo);
  const response = await promotionService.getTotalNumberOfPromo();
  return response.totalPromotions;
});

const totalNumberOfPromoSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalNumberOfPromo.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalNumberOfPromo.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response!.totalPromotions = payload;
    });
    builder.addCase(totalNumberOfPromo.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalNumberOfPromoSlice.reducer;
