import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { UpdateProductState, UpdateProductPayload } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'UPDATE_PRODUCT';

const initialState: UpdateProductState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const updateProduct = createAsyncThunk(`${NAMESPACE}/updateProduct`, async (payload: UpdateProductPayload) => {
  const productsRepo = new ProductsRepository();
  const productsService = new ProductsService(productsRepo);
  const response = await productsService.updateProduct(payload);
  return response;
});

const updateProductSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetEditProductStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProduct.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(updateProduct.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(updateProduct.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetEditProductStatus } = updateProductSlice.actions;

export default updateProductSlice.reducer;
