import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetBanksState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'GET_BANKS';

const initialState: GetBanksState = {
  status: HttpStatus.IDLE,
  message: undefined,
  listOfBanks: [],
};

export const getBanks = createAsyncThunk(`${NAMESPACE}/getBanks`, async () => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.getBanks();
  return response;
});

const getBanksSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBanks.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getBanks.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
      state.listOfBanks = action.payload;
    });
    builder.addCase(getBanks.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getBanksSlice.reducer;
