export enum OnboardingSteps {
  Business = 'BusinessPage',
  Vendor = 'VendorPage',
}

export const hasNextPage = (currentPage: OnboardingSteps): boolean => {
  const values = Object.values(OnboardingSteps);

  const position = values.indexOf(currentPage);
  if (position === -1) {
    return false;
  }

  return position < values.length - 1;
};

export const hasPreviousPage = (currentPage: OnboardingSteps): boolean => {
  const values = Object.values(OnboardingSteps);

  const position = values.indexOf(currentPage);
  if (position === -1) {
    return false;
  }

  return position > 0;
};

export const getPreviousPage = (currentPage: OnboardingSteps): OnboardingSteps => {
  const values = Object.values(OnboardingSteps);

  const position = values.indexOf(currentPage);
  if (position === -1) {
    return values[0];
  }
  const previousIndex = position - 1 < 0 ? 0 : position - 1;
  return values[previousIndex];
};

export const getNextPage = (currentPage: OnboardingSteps): OnboardingSteps => {
  const values = Object.values(OnboardingSteps);

  const position = values.indexOf(currentPage);
  if (position === -1) {
    return values[0];
  }
  const nextIndex = position + 1 > values.length - 1 ? 0 : position + 1;
  return values[nextIndex];
};

export const getCurrentPageNumber = (currentPage: OnboardingSteps): number => {
  const values = Object.values(OnboardingSteps);

  const position = values.indexOf(currentPage);

  return position <= 0 ? 1 : position + 1;
};

export const getTotalPageNumber = (): number => Object.values(OnboardingSteps).length;

export const getProgressPercentage = (currentPage: OnboardingSteps): number => {
  const values = Object.values(OnboardingSteps);
  const total = values.length;
  const position = values.indexOf(currentPage) + 1;
  return (position / total) * 100;
};
