import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '../../utils';
import { HttpStatus } from '../../commons';

const NAMESPACE = 'BUSINESS_TYPE';

export interface GetStatesInitialState {
  status: HttpStatus;
  error?: string;
  businessType: [];
}

const initialState: GetStatesInitialState = {
  status: HttpStatus.IDLE,
  error: undefined,
  businessType: [],
};

export const businessTypeSelect = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const response = await Api.get<[], []>('/v1/vendor/business-types');
  return response;
});

export const businessTypeSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(businessTypeSelect.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(businessTypeSelect.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
      state.businessType = action.payload;
    });
    builder.addCase(businessTypeSelect.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.error = error?.message ?? String(error);
    });
  },
});

export default businessTypeSlice.reducer;
