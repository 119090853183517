import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../components/utils';

const initialState = {
  page: PAGES.profile,
};

const settingSlice = createSlice({
  name: 'settingPage',
  initialState: initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = settingSlice.actions;

export default settingSlice.reducer;
