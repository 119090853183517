import { Api } from '../../../utils';
import {
  AddPaymentDetailsPayload,
  AddPaymentDetailsResponsePayload,
  ChangePasswordPayload,
  ChangePasswordResponsePayload,
  GetBanksResponsePayload,
  GetNotificationResponsePayload,
  GetVendorBankDetailsResponse,
  UpdateNotificationPayload,
  UpdateNotificationResponsePayload,
  UserDetailsResponsePayload,
  VerifyBankDetailsPayload,
  VerifyBankDetailsResponsePayload,
} from '../setting_types';

export class SettingRepository {
  async addPaymentDetails(payload: AddPaymentDetailsPayload): Promise<AddPaymentDetailsResponsePayload> {
    const response = await Api.post<AddPaymentDetailsResponsePayload, AddPaymentDetailsResponsePayload>(
      'v1/vendor/setting/add-payment-details',
      payload
    );
    return response;
  }

  async getBanks(): Promise<GetBanksResponsePayload[]> {
    const response = await Api.get<GetBanksResponsePayload[], GetBanksResponsePayload[]>('v1/vendor/banks');
    return response;
  }

  async changePassword(payload: ChangePasswordPayload): Promise<ChangePasswordResponsePayload> {
    const response = await Api.put<ChangePasswordResponsePayload, ChangePasswordResponsePayload>(
      'v1/vendor/setting/change-password',
      payload
    );
    return response;
  }

  async getBankDetails(): Promise<GetVendorBankDetailsResponse> {
    const response = await Api.get<GetVendorBankDetailsResponse, GetVendorBankDetailsResponse>(
      'v1/vendor/setting/bank-details'
    );
    return response;
  }

  async getNotification(): Promise<GetNotificationResponsePayload> {
    const response = await Api.get<GetNotificationResponsePayload, GetNotificationResponsePayload>(
      'v1/vendor/setting/notification'
    );
    return response;
  }

  async updateNotification(payload: UpdateNotificationPayload): Promise<UpdateNotificationResponsePayload> {
    const response = await Api.put<UpdateNotificationResponsePayload, UpdateNotificationResponsePayload>(
      'v1/vendor/setting/update-notification',
      payload
    );
    return response;
  }

  async getUserDetails(): Promise<UserDetailsResponsePayload> {
    const response = await Api.get<UserDetailsResponsePayload, UserDetailsResponsePayload>('v1/vendor/setting/details');
    return response;
  }

  async verifyBankDetails(payload: VerifyBankDetailsPayload): Promise<VerifyBankDetailsResponsePayload> {
    const response = await Api.post<VerifyBankDetailsResponsePayload, VerifyBankDetailsResponsePayload>(
      'v1/vendor/account/verify',
      payload
    );
    return response;
  }
}
