import { SettingRepository } from '../repositories/setting.repo';
import {
  AddPaymentDetailsPayload,
  AddPaymentDetailsResponsePayload,
  ChangePasswordPayload,
  ChangePasswordResponsePayload,
  GetBanksResponsePayload,
  GetNotificationResponsePayload,
  GetVendorBankDetailsResponse,
  UpdateNotificationPayload,
  UpdateNotificationResponsePayload,
  UserDetailsResponsePayload,
  VerifyBankDetailsPayload,
  VerifyBankDetailsResponsePayload,
} from '../setting_types';

export class SettingService {
  constructor(private readonly settingRepo: SettingRepository) {}

  async addPaymentDetails(payload: AddPaymentDetailsPayload): Promise<AddPaymentDetailsResponsePayload> {
    const addPaymentDetailsResponse = await this.settingRepo.addPaymentDetails(payload);
    return addPaymentDetailsResponse;
  }

  async getBanks(): Promise<GetBanksResponsePayload[]> {
    const getBanksResponse = await this.settingRepo.getBanks();
    return getBanksResponse;
  }

  async changePassword(payload: ChangePasswordPayload): Promise<ChangePasswordResponsePayload> {
    const changePasswordResponse = await this.settingRepo.changePassword(payload);
    return changePasswordResponse;
  }

  async getBankDetails(): Promise<GetVendorBankDetailsResponse> {
    const getBankDetailsResponse = await this.settingRepo.getBankDetails();
    return getBankDetailsResponse;
  }

  async getNotification(): Promise<GetNotificationResponsePayload> {
    const getNotificationResponse = await this.settingRepo.getNotification();
    return getNotificationResponse;
  }

  async updateNotification(payload: UpdateNotificationPayload): Promise<UpdateNotificationResponsePayload> {
    const updateNotificationResponse = await this.settingRepo.updateNotification(payload);
    return updateNotificationResponse;
  }

  async getUserDetails(): Promise<UserDetailsResponsePayload> {
    const getUserDetailsResponse = await this.settingRepo.getUserDetails();
    return getUserDetailsResponse;
  }

  async verifyBankDetails(payload: VerifyBankDetailsPayload): Promise<VerifyBankDetailsResponsePayload> {
    const verifyBankDetailsResponse = await this.settingRepo.verifyBankDetails(payload);
    return verifyBankDetailsResponse;
  }
}
