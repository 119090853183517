import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { SignUpRepository } from '../repositories/sign_up.repo';
import { SignUpService } from '../services/sign_up.service';
import { OnboardVendorState } from '../types';

const NAMESPACE = 'ONBOARD_VENDOR';

const initialState: OnboardVendorState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const onboardVendor = createAsyncThunk(`${NAMESPACE}/post`, async () => {
  const signupRepo = new SignUpRepository();
  const signupService = new SignUpService(signupRepo);
  const response = await signupService.onboardVendor();
  return response;
});

export const stateSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetSignUpStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onboardVendor.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(onboardVendor.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.vendorId);
      state.status = HttpStatus.DONE;
    });
    builder.addCase(onboardVendor.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
      console.log(error);
    });
  },
});

export const { resetSignUpStatus } = stateSlice.actions;

export default stateSlice.reducer;
