import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetProductDetailsState, ProductOutOfStockRequestPayload } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'PRODUCT_OF_STOCK';

const initialState: GetProductDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getProductStock = createAsyncThunk(
  `${NAMESPACE}/products`,
  async (payload: ProductOutOfStockRequestPayload) => {
    const productsRepo = new ProductsRepository();
    const productsService = new ProductsService(productsRepo);
    const response = await productsService.updateProductStockAlert(payload);
    return response.lowStock;
  }
);

const getProductStockSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetProductLowStockStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductStock.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getProductStock.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(getProductStock.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetProductLowStockStatus } = getProductStockSlice.actions;

export default getProductStockSlice.reducer;
