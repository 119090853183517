import { Api } from '../../../utils';
import {
  CreatePromoPayload,
  CreatePromoResponsePayload,
  GetPromoItemDetailsPayload,
  GetPromoItemDetailsResponsePayload,
  GetPromoPayload,
  ReactivatePromoPayload,
  ReactivatePromoResponsePayload,
  TotalNumberOfPromoResponsePayload,
  UpdatePromoPayload,
  UpdatePromoResponsePayload,
} from '../promotion_types';

export class PromotionRepository {
  async getActivePromo(): Promise<GetPromoPayload> {
    const response = await Api.get<GetPromoPayload, GetPromoPayload>('v1/promotion/active-promotions');
    return response;
  }

  async createPromo(payload: CreatePromoPayload): Promise<CreatePromoResponsePayload> {
    const response = await Api.post<CreatePromoResponsePayload, CreatePromoResponsePayload>(
      'v1/promotion/create',
      payload
    );
    return response;
  }

  async getExpiredPromo(): Promise<GetPromoPayload> {
    const response = await Api.get<GetPromoPayload, GetPromoPayload>('v1/promotion/expired-promotions');
    return response;
  }

  async getPromoDetails(payload: GetPromoItemDetailsPayload): Promise<GetPromoItemDetailsResponsePayload> {
    const response = await Api.get<GetPromoItemDetailsResponsePayload, GetPromoItemDetailsResponsePayload>(
      'v1/promotion/promotion-details',
      { params: payload }
    );
    return response;
  }

  async getTotalNumberOfActivePromo(): Promise<TotalNumberOfPromoResponsePayload> {
    const response = await Api.get<TotalNumberOfPromoResponsePayload, TotalNumberOfPromoResponsePayload>(
      'v1/promotion/total-active-promotions'
    );
    return response;
  }

  async getTotalNumberOfPromo(): Promise<TotalNumberOfPromoResponsePayload> {
    const response = await Api.get<TotalNumberOfPromoResponsePayload, TotalNumberOfPromoResponsePayload>(
      'v1/promotion/total-promotion'
    );
    return response;
  }

  async reactivatePromo(payload: ReactivatePromoPayload): Promise<ReactivatePromoResponsePayload> {
    const response = await Api.put<ReactivatePromoResponsePayload, ReactivatePromoResponsePayload>(
      'v1/promotion/reactivate-promotion',
      payload
    );
    return response;
  }

  async updatePromo(payload: UpdatePromoPayload): Promise<UpdatePromoResponsePayload> {
    const response = await Api.put<UpdatePromoResponsePayload, UpdatePromoResponsePayload>(
      'v1/promotion/update-status',
      payload
    );
    return response;
  }
}
