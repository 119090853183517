import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { UserDetailsState, VendorStatus } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'USER_DETAILS';

const initialState: UserDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: {
    vendorId: '',
    name: 'Hello User',
    address: 'No 1 street name, State.',
    phone: '0000-0000-0000',
    email: 'example@gmail.com',
    logo: '',
    type: '',
    status: VendorStatus.PENDING,
    proofOfAddress: [],
    accountName: '',
    accountNumber: '',
    bankName: '',
    entityStatus: { status: '', at: '' },
    isBusinessRegistered: '',
  },
};

export const userDetails = createAsyncThunk(`${NAMESPACE}/userDetails`, async () => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.getUserDetails();
  return response;
});

const userDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
      state.response = action.payload;
    });
    builder.addCase(userDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
