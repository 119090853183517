import { Api } from '../../../utils';
import { LoginResponsePayload, OnboardVendorResponsePayload, VerifyOTPResponsePayload } from '../../signUp/types';

export class LoginRepository {
  async verifyUserEmail(payload: LoginResponsePayload): Promise<OnboardVendorResponsePayload> {
    const { email } = payload;
    window.sessionStorage.setItem('email', email);
    const response = await Api.post<OnboardVendorResponsePayload, OnboardVendorResponsePayload>(
      '/v1/vendor/login',
      payload
    );
    return response;
  }

  async verifyUserOTP(otp: string): Promise<VerifyOTPResponsePayload> {
    const email = window.sessionStorage.getItem('email');
    const payload = { otp, email };
    const response = await Api.post<VerifyOTPResponsePayload, VerifyOTPResponsePayload>(
      '/v1/vendor/verify-otp',
      payload
    );
    return response;
  }
}
