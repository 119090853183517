import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetPromoState } from '../promotion_types';
import { PromotionRepository } from '../repositories/promotion.repo';
import { PromotionService } from '../services/promotion.service';

const NAMESPACE = 'EXPIRED_PROMO';

const initialState: GetPromoState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const expiredPromo = createAsyncThunk(`${NAMESPACE}/order`, async () => {
  const promotionRepo = new PromotionRepository();
  const promotionService = new PromotionService(promotionRepo);
  const response = await promotionService.getExpiredPromo();
  return response;
});

export const expiredPromoSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(expiredPromo.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(expiredPromo.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(expiredPromo.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default expiredPromoSlice.reducer;
