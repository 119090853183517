import {
  CreatePromoPayload,
  CreatePromoResponsePayload,
  GetPromoItemDetailsPayload,
  GetPromoItemDetailsResponsePayload,
  GetPromoPayload,
  ReactivatePromoPayload,
  ReactivatePromoResponsePayload,
  TotalNumberOfPromoResponsePayload,
  UpdatePromoPayload,
  UpdatePromoResponsePayload,
} from '../promotion_types';
import { PromotionRepository } from '../repositories/promotion.repo';

export class PromotionService {
  constructor(private readonly promotionRepo: PromotionRepository) {}

  async getActivePromo(): Promise<GetPromoPayload> {
    const getActivePromoResponse = await this.promotionRepo.getActivePromo();
    return getActivePromoResponse;
  }

  async createPromo(payload: CreatePromoPayload): Promise<CreatePromoResponsePayload> {
    const createPromoResponse = await this.promotionRepo.createPromo(payload);
    return createPromoResponse;
  }

  async getExpiredPromo(): Promise<GetPromoPayload> {
    const getExpiredPromoResponse = await this.promotionRepo.getExpiredPromo();
    return getExpiredPromoResponse;
  }

  async getPromoDetails(payload: GetPromoItemDetailsPayload): Promise<GetPromoItemDetailsResponsePayload> {
    const getPromoDetailsResponse = await this.promotionRepo.getPromoDetails(payload);
    return getPromoDetailsResponse;
  }

  async getTotalNumberOfActivePromo(): Promise<TotalNumberOfPromoResponsePayload> {
    const getTotalNumberOfActivePromoResponse = await this.promotionRepo.getTotalNumberOfActivePromo();
    return getTotalNumberOfActivePromoResponse;
  }

  async getTotalNumberOfPromo(): Promise<TotalNumberOfPromoResponsePayload> {
    const getTotalNumberOfPromoResponse = await this.promotionRepo.getTotalNumberOfPromo();
    return getTotalNumberOfPromoResponse;
  }

  async reactivatePromo(payload: ReactivatePromoPayload): Promise<ReactivatePromoResponsePayload> {
    const reactivatePromoResponse = await this.promotionRepo.reactivatePromo(payload);
    return reactivatePromoResponse;
  }

  async updatePromo(payload: UpdatePromoPayload): Promise<UpdatePromoResponsePayload> {
    const updatePromoResponse = await this.promotionRepo.updatePromo(payload);
    return updatePromoResponse;
  }
}
