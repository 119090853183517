import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { LoginState, LoginResponsePayload } from '../../signUp/types';
import { LoginRepository } from '../repositories/login.repo';
import { LoginService } from '../services/login.service';

const NAMESPACE = 'LOGIN_VENDOR';

const initialState: LoginState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const login = createAsyncThunk(`${NAMESPACE}/login`, async (payload: LoginResponsePayload) => {
  const loginRepo = new LoginRepository();
  const loginService = new LoginService(loginRepo);
  const response = await loginService.verifyUserEmail(payload);
  return response;
});

export const LoginSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetAuthState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.vendorId);
      state.status = HttpStatus.DONE;
    });
    builder.addCase(login.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetAuthState } = LoginSlice.actions;

export default LoginSlice.reducer;
