import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ResetPasswordState, ResetPasswordPayload } from '../types';
import { ForgotPasswordRepository } from '../repository/forgot_password.repo';
import { ForgotPasswordService } from '../service/forgot_password.service';

const NAMESPACE = 'RESET_PASSWORD';

const initialState: ResetPasswordState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const resetPassword = createAsyncThunk(NAMESPACE, async (payload: ResetPasswordPayload) => {
  const forgotPasswordRepo = new ForgotPasswordRepository();
  const forgotPasswordService = new ForgotPasswordService(forgotPasswordRepo);
  const response = await forgotPasswordService.resetPassword(payload);
  return response;
});

export const resetPasswordSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetPassword.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(resetPassword.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
