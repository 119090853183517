import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ListOfActiveAdvertsState } from '../adverts_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { AdvertsRepository } from '../repositories/adverts.repo';
import { AdvertsService } from '../services/adverts.service';

const NAMESPACE = 'LIST_OF_EXPIRED_ADVERTS';

const initialState: ListOfActiveAdvertsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  adverts: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const listOfExpiredAdverts = createAsyncThunk(`${NAMESPACE}/adverts`, async (arg?: PaginationPayload) => {
  const advertsRepo = new AdvertsRepository();
  const advertsService = new AdvertsService(advertsRepo);
  const response = await advertsService.getListOfExpiredAdverts(arg);
  return response;
});

const listOfExpiredAdvertsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOfExpiredAdverts.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(listOfExpiredAdverts.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.adverts = payload.adverts;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(listOfExpiredAdverts.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default listOfExpiredAdvertsSlice.reducer;
