import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetAllPayoutRequestResponsePayloadState } from '../accounting_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { AccountingRepository } from '../repositories/accounting.repo';
import { AccountingService } from '../services/accounting.service';

const NAMESPACE = 'GET_PAYOUT_REQUEST';

const initialState: GetAllPayoutRequestResponsePayloadState = {
  status: HttpStatus.IDLE,
  message: undefined,
  withdrawals: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const getAllPendingPayoutRequest = createAsyncThunk(
  `${NAMESPACE}/payoutRequest`,
  async (arg?: PaginationPayload) => {
    const accountingRepo = new AccountingRepository();
    const accountingService = new AccountingService(accountingRepo);
    const response = accountingService.getAllPendingPayoutRequest(arg);
    return response;
  }
);

export const getAllPendingPayoutRequestSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPendingPayoutRequest.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getAllPendingPayoutRequest.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.withdrawals = payload.withdrawals;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getAllPendingPayoutRequest.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getAllPendingPayoutRequestSlice.reducer;
