import { Api } from '../../../utils';
import { GetTotalEarningsWithDateResponsePayload, getTotalEarnedWithinDateRangePayload } from '../analytics_types';

export class AnalyticsRepository {
  async getTotalEarnedWithinDateRange(
    payload: getTotalEarnedWithinDateRangePayload
  ): Promise<GetTotalEarningsWithDateResponsePayload> {
    const response = await Api.get<GetTotalEarningsWithDateResponsePayload, GetTotalEarningsWithDateResponsePayload>(
      'v1/vendor/total-earned-with-date',
      { params: payload }
    );
    return response;
  }
}
