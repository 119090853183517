import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { TotalNumberOfAdvertsState } from '../adverts_types';
import { AdvertsRepository } from '../repositories/adverts.repo';
import { AdvertsService } from '../services/adverts.service';

const NAMESPACE = 'TOTAL_NUMBER_OF_ADVERTS';

const initialState: TotalNumberOfAdvertsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalAdverts: 0 },
};

export const totalNumberOfAdvert = createAsyncThunk(`${NAMESPACE}/adverts`, async () => {
  const advertsRepo = new AdvertsRepository();
  const advertsService = new AdvertsService(advertsRepo);
  const response = await advertsService.getTotalNumberOfAdvert();
  return response.totalAdverts;
});

const totalNumberOfAdvertSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalNumberOfAdvert.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalNumberOfAdvert.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response!.totalAdverts = payload;
    });
    builder.addCase(totalNumberOfAdvert.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalNumberOfAdvertSlice.reducer;
