import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { VerifySignUpOtpState } from '../types';
import { SignUpRepository } from '../repositories/sign_up.repo';
import { SignUpService } from '../services/sign_up.service';

const NAMESPACE = 'SIGNUP_OTP';

const initialState: VerifySignUpOtpState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const verifySignUpOtp = createAsyncThunk(`${NAMESPACE}/signupOtp`, async (otp: string) => {
  const signupRepo = new SignUpRepository();
  const signupService = new SignUpService(signupRepo);
  const response = await signupService.verifySignupOtp(otp);
  return response;
});

const signUpOtpSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifySignUpOtp.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(verifySignUpOtp.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.vendorId);
      state.status = HttpStatus.DONE;
    });
    builder.addCase(verifySignUpOtp.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default signUpOtpSlice.reducer;
