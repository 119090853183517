import { Flex, Box, Button, Image } from '@chakra-ui/react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/ORIGINAL.png';
import { authRoutes } from '../../commons/types/routes';

export default function AuthenticationLayout(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Box paddingX={{ base: 'base', md: '5xl', lg: '7xl', '2xl': 'xxl' }}>
      <Flex alignItems="center" justifyContent="space-between" h="12vh">
        <Box as={Link} to="/" overflow="hidden" boxSize={{ base: '4rem', md: '5rem' }}>
          <Image src={Logo} alt="Logo" w="full" h="full" objectFit="contain" />
        </Box>
        <Button
          onClick={() => navigate(authRoutes.signUp)}
          borderRadius="full"
          bgColor="#000"
          color="#fff"
          _hover={{ bgColor: '#000', color: '#fff' }}
          _active={{ bgColor: '#000', color: '#fff' }}
          boxShadow="0px 10px 15px rgba(0, 0, 0, 0.08)"
          px={{ base: '3xl', lg: '4xl' }}
          py={{ base: '2xl', lg: '2xl' }}
        >
          Sign up
        </Button>
      </Flex>
      <Box
        as="main"
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        w="full"
        sx={{
          height: 'calc(88vh - 12vh)',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
