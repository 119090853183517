import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetAllPayoutRequestResponsePayload,
  GetBalanceResponsePayload,
  RequestPayoutPayload,
  RequestPayoutResponsePayload,
} from '../accounting_types';

export class AccountingRepository {
  async getPayoutHistory(arg?: PaginationPayload): Promise<GetAllPayoutRequestResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetAllPayoutRequestResponsePayload, GetAllPayoutRequestResponsePayload>(
      'v1/vendor/withdrawal-history',
      {
        params,
      }
    );
    return response;
  }

  async getAllPendingPayoutRequest(arg?: PaginationPayload): Promise<GetAllPayoutRequestResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetAllPayoutRequestResponsePayload, GetAllPayoutRequestResponsePayload>(
      'v1/vendor/pending-withdrawal',
      {
        params,
      }
    );
    return response;
  }

  async getBalance(): Promise<GetBalanceResponsePayload> {
    const response = await Api.get<GetBalanceResponsePayload, GetBalanceResponsePayload>('v1/vendor/new-balance');
    return response;
  }

  async requestPayout(payload: RequestPayoutPayload): Promise<RequestPayoutResponsePayload> {
    const response = await Api.post<RequestPayoutResponsePayload, RequestPayoutResponsePayload>(
      'v1/vendor/payout',
      payload
    );
    return response;
  }
}
