import { PaginationPayload } from '../../../commons/types/pagination.type';
import { ReviewRepository } from '../repositories/review.repo';
import {
  GetRatingResponsePayload,
  GetReviewResponsePayload,
  ReplyReviewPayload,
  ReplyReviewResponsePayload,
} from '../reviews_types';

export class ReviewService {
  constructor(private readonly reviewRepo: ReviewRepository) {}

  async getRating(): Promise<GetRatingResponsePayload> {
    const getRatingResponse = await this.reviewRepo.getRating();
    return getRatingResponse;
  }

  async getReview(arg?: PaginationPayload): Promise<GetReviewResponsePayload> {
    const getReviewResponse = await this.reviewRepo.getReview(arg);
    return getReviewResponse;
  }

  async replyReview(payload: ReplyReviewPayload): Promise<ReplyReviewResponsePayload> {
    const replyReviewResponse = await this.reviewRepo.replyReview(payload);
    return replyReviewResponse;
  }
}
