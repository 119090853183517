import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../components/utils';

const NAMESPACE = 'ADVERT_PAGE_CHANGE';

const initialState = {
  page: PAGES.pending,
};

export const advertSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = advertSlice.actions;

export default advertSlice.reducer;
