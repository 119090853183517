import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { RequestResetPasswordState, RequestResetPasswordPayload } from '../types';
import { ForgotPasswordRepository } from '../repository/forgot_password.repo';
import { ForgotPasswordService } from '../service/forgot_password.service';

const NAMESPACE = 'REQUEST_RESET_PASSWORD';

const initialState: RequestResetPasswordState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const requestResetPassword = createAsyncThunk(NAMESPACE, async (payload: RequestResetPasswordPayload) => {
  const forgotPasswordRepo = new ForgotPasswordRepository();
  const forgotPasswordService = new ForgotPasswordService(forgotPasswordRepo);
  const response = forgotPasswordService.requestResetPassword(payload);
  return response;
});

export const requestResetPasswordSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(requestResetPassword.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(requestResetPassword.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.vendorId);
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(requestResetPassword.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = requestResetPasswordSlice.actions;

export default requestResetPasswordSlice.reducer;
