import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { AddPaymentDetailsPayload, AddPaymentDetailsState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'ADD_PAYMENT_DETAILS';

const initialState: AddPaymentDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const addPaymentDetails = createAsyncThunk(`${NAMESPACE}/payment`, async (payload: AddPaymentDetailsPayload) => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.addPaymentDetails(payload);
  return response;
});

const addPaymentDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetPaymentStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addPaymentDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(addPaymentDetails.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(addPaymentDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetPaymentStatus } = addPaymentDetailsSlice.actions;

export default addPaymentDetailsSlice.reducer;
