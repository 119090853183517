import { createSlice } from '@reduxjs/toolkit';
import { OnboardingFormDataInitialState } from '../types';

import { OnboardingSteps } from '../utils';
import { VENDOR_FORM_DATA_STORAGE_KEY } from '../utils/constant';

const NAMESPACE = 'ONBOARDING_FORM_DATA';

const initialState: OnboardingFormDataInitialState = {
  businessName: '',
  businessAddress: '',
  state: '',
  businessType: '',
  isBusinessRegistered: 'no',
  cacNumber: '',
  identificationDocument: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  hasAgreedToTermsAndConditions: false,
};

export interface FormActionPayload {
  currentStep: OnboardingSteps;
  key: string;
  value: string;
}

export const onBoardingFormSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setFormData: (state, action) => {
      const formData = window.sessionStorage.getItem(VENDOR_FORM_DATA_STORAGE_KEY);
      if (formData) {
        const prevFormData = JSON.parse(formData);
        state = { ...prevFormData, ...action.payload };
        window.sessionStorage.setItem(VENDOR_FORM_DATA_STORAGE_KEY, JSON.stringify(state));
      } else {
        state = { ...action.payload };
        window.sessionStorage.setItem(VENDOR_FORM_DATA_STORAGE_KEY, JSON.stringify(state));
      }
    },
    reset: (state, action) => {
      window.sessionStorage.removeItem(VENDOR_FORM_DATA_STORAGE_KEY);
      state = initialState;
    },
  },
});

export const { setFormData, reset } = onBoardingFormSlice.actions;

export default onBoardingFormSlice.reducer;
