import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Spinner from './components/spinner/spinner';
import { authRoutes, sidebarRoutes } from './commons/types/routes';
import AuthenticationLayout from './layout/authentication/authentication_layout';

const Home = lazy(() => import('./pages/home'));
const UploadProofOfAddress = lazy(() => import('./pages/signUp/components/add_proof_of_address'));
const OtpVerification = lazy(() => import('./pages/signUp/components/otp_verification'));
const SignUpLayout = lazy(() => import('./layout/signUp/signUpLayout'));
const SignUp = lazy(() => import('./pages/signUp/signUp'));
const Login = lazy(() => import('./pages/login'));
const LoginOtp = lazy(() => import('./pages/login/components/otp_verification'));
const UploadLogo = lazy(() => import('./pages/signUp/components/upload_logo'));
const TermsOfService = lazy(() => import('./pages/termsOfServices/index'));
const RequestResetPassword = lazy(() => import('./pages/forgotPassword/components/request_reset_password'));
const ForgotPasswordOtp = lazy(() => import('./pages/forgotPassword/components/verify_otp'));
const ResetPassword = lazy(() => import('./pages/forgotPassword/index'));

const Reviews = lazy(() => import('./pages/reviews/reviews'));
const Settings = lazy(() => import('./pages/settings/settings'));
const Promotion = lazy(() => import('./pages/promotion/promotion'));
const ReactivatePromo = lazy(() => import('./pages/promotion/components/reactivatePromo'));
const AddNewPromotion = lazy(() => import('./pages/promotion/components/addPromo/add_new'));
const Products = lazy(() => import('./pages/products/products'));
const UpdateProduct = lazy(() => import('./pages/products/components/updateProduct/update_product'));
const ProductDetails = lazy(() => import('./pages/products/components/productDetails/product_details'));
const AddProducts = lazy(() => import('./pages/products/components/addProducts.tsx/add_products'));
const Orders = lazy(() => import('./pages/orders/orders'));
const OrderDetails = lazy(() => import('./pages/orders/components/orderDetails/orderDetails'));
const Help = lazy(() => import('./pages/help/help'));
const Analytics = lazy(() => import('./pages/analytics/analytics'));
const AddNewAdvert = lazy(() => import('./pages/adverts/components/add_advert'));
const Adverts = lazy(() => import('./pages/adverts/adverts'));
const Accounting = lazy(() => import('./pages/accounting/accounting'));
const MainLayout = lazy(() => import('./layout/dashboard/main_layout'));
const PageNotFound = lazy(() => import('./pages/error'));

function App() {
  return (
    <Suspense fallback={<Spinner message="Building page..." />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="" element={<AuthenticationLayout />}>
            <Route index path={authRoutes.signIn} element={<Login />} />
            <Route path={authRoutes.signInOTP} element={<LoginOtp />} />
            <Route path={authRoutes.signUpOTP} element={<OtpVerification />} />
            <Route path={authRoutes.requestResetPassword} element={<RequestResetPassword />} />
            <Route path={authRoutes.requestResetPasswordOTP} element={<ForgotPasswordOtp />} />
            <Route path={authRoutes.resetPassword} element={<ResetPassword />} />
            <Route path={authRoutes.proofOfAddressUpload} element={<UploadProofOfAddress />} />
            <Route path={authRoutes.logoUpload} element={<UploadLogo />} />
          </Route>
          <Route
            path={authRoutes.signUp}
            element={
              <SignUpLayout>
                <SignUp />
              </SignUpLayout>
            }
          />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          <Route path={sidebarRoutes.dashboard} element={<MainLayout />}>
            <Route index path={sidebarRoutes.analytics} element={<Analytics />} />
            <Route path={sidebarRoutes.products} element={<Products />} />
            <Route path={sidebarRoutes.addProduct} element={<AddProducts />} />
            <Route path={sidebarRoutes.viewProduct} element={<ProductDetails />} />
            <Route path={sidebarRoutes.editProduct} element={<UpdateProduct />} />
            <Route path={sidebarRoutes.orders} element={<Orders />} />
            <Route path={sidebarRoutes.viewOrder} element={<OrderDetails />} />
            <Route path={sidebarRoutes.promo} element={<Promotion />} />
            <Route path={sidebarRoutes.addPromo} element={<AddNewPromotion />} />
            <Route path={sidebarRoutes.reactivatePromo} element={<ReactivatePromo />} />
            <Route path={sidebarRoutes.adverts} element={<Adverts />} />
            <Route path={sidebarRoutes.addAdvert} element={<AddNewAdvert />} />
            <Route path={sidebarRoutes.accounting} element={<Accounting />} />
            <Route path={sidebarRoutes.review} element={<Reviews />} />
            <Route path={sidebarRoutes.settings} element={<Settings />} />
            <Route path={sidebarRoutes.help} element={<Help />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
