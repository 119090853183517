import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetRatingResponsePayload,
  GetReviewResponsePayload,
  ReplyReviewPayload,
  ReplyReviewResponsePayload,
} from '../reviews_types';

export class ReviewRepository {
  async getRating(): Promise<GetRatingResponsePayload> {
    const response = await Api.get<GetRatingResponsePayload, GetRatingResponsePayload>('v1/rating/get-rating');
    return response;
  }

  async getReview(arg?: PaginationPayload): Promise<GetReviewResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetReviewResponsePayload, GetReviewResponsePayload>('v1/rating/get-review', {
      params,
    });
    return response;
  }

  async replyReview(payload: ReplyReviewPayload): Promise<ReplyReviewResponsePayload> {
    const response = await Api.put<ReplyReviewResponsePayload, ReplyReviewResponsePayload>(
      'v1/review/reply-review',
      payload
    );
    return response;
  }
}
