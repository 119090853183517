import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetProductDetailsPayload, GetProductDetailsState } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'GET_PRODUCT_DETAILS';

const initialState: GetProductDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getProductDetails = createAsyncThunk(
  `${NAMESPACE}/productDetails`,
  async (payload: GetProductDetailsPayload) => {
    const productsRepo = new ProductsRepository();
    const productsService = new ProductsService(productsRepo);
    const response = await productsService.getProductDetails(payload);
    return response;
  }
);

export const getProductDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getProductDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getProductDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = getProductDetailsSlice.actions;

export default getProductDetailsSlice.reducer;
