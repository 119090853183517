import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { TotalOrderPerDayPayload, TotalOrderPerDayState } from '../order_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'TOTAL_ORDERS_PER_DAY';

const initialState: TotalOrderPerDayState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { sunday: 0, monday: 0, tuesday: 0, wednesday: 0, thursday: 0, friday: 0, saturday: 0 },
};

export const totalOrdersPerDay = createAsyncThunk(
  `${NAMESPACE}/totalOrder`,
  async (payload: TotalOrderPerDayPayload) => {
    const orderRepo = new OrderRepository();
    const orderService = new OrderService(orderRepo);
    const response = await orderService.getTotalOrderPerDay(payload);
    return response;
  }
);

export const totalOrdersPerDaySlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalOrdersPerDay.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalOrdersPerDay.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalOrdersPerDay.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalOrdersPerDaySlice.reducer;
