import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { VerifyBankDetailsPayload, VerifyBankDetailsState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'VERIFY_BANK_DETAILS';

const initialState: VerifyBankDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const verifyBankDetails = createAsyncThunk(`${NAMESPACE}/payment`, async (payload: VerifyBankDetailsPayload) => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.verifyBankDetails(payload);
  return response;
});

const verifyBankDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetVerifyStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyBankDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(verifyBankDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(verifyBankDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetVerifyStatus } = verifyBankDetailsSlice.actions;

export default verifyBankDetailsSlice.reducer;
