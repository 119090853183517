import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { CreateProductState, CreateProductPayload } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'CREATE_PRODUCT';

const initialState: CreateProductState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const createProduct = createAsyncThunk(`${NAMESPACE}/createProduct`, async (payload: CreateProductPayload) => {
  const productsRepo = new ProductsRepository();
  const productsService = new ProductsService(productsRepo);
  const response = await productsService.createProduct(payload);
  return response;
});

const createProductSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProduct.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(createProduct.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(createProduct.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default createProductSlice.reducer;
