import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetTotalOrderResponsePayloadInitialState } from '../order_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'TOTAL_ORDERS';

const initialState: GetTotalOrderResponsePayloadInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalOrders: 0 },
};

export const totalOrders = createAsyncThunk(NAMESPACE, async () => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.getTotalOrders();
  return response;
});

const totalOrdersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalOrdersSlice.reducer;
