import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetBalanceState } from '../accounting_types';
import { AccountingRepository } from '../repositories/accounting.repo';
import { AccountingService } from '../services/accounting.service';

const NAMESPACE = 'GET_BALANCE';

const initialState: GetBalanceState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { vendorId: '', balance: 0, balanceFormatted: '0' },
};

export const getBalance = createAsyncThunk(`${NAMESPACE}/balance`, async () => {
  const accountingRepo = new AccountingRepository();
  const accountingService = new AccountingService(accountingRepo);
  const response = accountingService.getBalance();
  return response;
});

const getBalanceSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetBalanceStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBalance.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getBalance.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getBalance.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetBalanceStatus } = getBalanceSlice.actions;

export default getBalanceSlice.reducer;
