import { Api } from '../../../utils';
import {
  RequestResetPasswordPayload,
  RequestResetPasswordResponsePayload,
  ResetPasswordPayload,
  ResetPasswordResponsePayload,
  VerifyResetPasswordOtpPayload,
  VerifyResetPasswordOtpResponsePayload,
} from '../types';

export class ForgotPasswordRepository {
  async requestResetPassword(payload: RequestResetPasswordPayload): Promise<RequestResetPasswordResponsePayload> {
    window.sessionStorage.setItem('email', payload.email);
    const response = await Api.post<RequestResetPasswordResponsePayload, RequestResetPasswordResponsePayload>(
      '/v1/vendor/request-reset-password',
      payload
    );
    return response;
  }

  async verifyResetPasswordOtp(payload: VerifyResetPasswordOtpPayload): Promise<VerifyResetPasswordOtpResponsePayload> {
    const response = await Api.post<VerifyResetPasswordOtpResponsePayload, VerifyResetPasswordOtpResponsePayload>(
      '/v1/vendor/verify-otp',
      payload
    );
    return response;
  }

  async resetPassword(payload: ResetPasswordPayload): Promise<ResetPasswordResponsePayload> {
    const response = await Api.put<ResetPasswordResponsePayload, ResetPasswordResponsePayload>(
      '/v1/vendor/reset-password',
      payload
    );
    return response;
  }
}
