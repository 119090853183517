import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ListOfPendingAdvertsState } from '../adverts_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { AdvertsRepository } from '../repositories/adverts.repo';
import { AdvertsService } from '../services/adverts.service';

const NAMESPACE = 'LIST_OF_PENDING_ADVERTS';

const initialState: ListOfPendingAdvertsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  pendingAdverts: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const listOfPendingAdverts = createAsyncThunk(`${NAMESPACE}/adverts`, async (arg?: PaginationPayload) => {
  const advertsRepo = new AdvertsRepository();
  const advertsService = new AdvertsService(advertsRepo);
  const response = await advertsService.getListOfPendingAdverts(arg);
  return response;
});

const listOfPendingAdvertsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOfPendingAdverts.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(listOfPendingAdverts.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.pendingAdverts = payload.adverts;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(listOfPendingAdverts.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default listOfPendingAdvertsSlice.reducer;
