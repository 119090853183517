import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { VerifyResetPasswordOtpState } from '../types';
import { ForgotPasswordRepository } from '../repository/forgot_password.repo';
import { ForgotPasswordService } from '../service/forgot_password.service';

const NAMESPACE = 'VERIFY_RESET_PASSWORD_OTP';

const initialState: VerifyResetPasswordOtpState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const verifyResetPasswordOtp = createAsyncThunk(NAMESPACE, async (otp: string) => {
  const email = String(window.sessionStorage.getItem('email'));
  const payload = { otp, email };
  const forgotPasswordRepo = new ForgotPasswordRepository();
  const forgotPasswordService = new ForgotPasswordService(forgotPasswordRepo);
  const response = forgotPasswordService.verifyResetPasswordOtp(payload);
  return response;
});

const verifyResetPasswordOtpSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(verifyResetPasswordOtp.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(verifyResetPasswordOtp.fulfilled, (state, { payload }) => {
      window.sessionStorage.removeItem('email');
      state.status = HttpStatus.DONE;
    });
    builder.addCase(verifyResetPasswordOtp.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = verifyResetPasswordOtpSlice.actions;

export default verifyResetPasswordOtpSlice.reducer;
