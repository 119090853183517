import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';

import { Api } from '../../utils';
import { HttpStatus } from '../../commons';

const NAMESPACE = 'COUNTRY_STATE';

export interface GetStatesInitialState {
  status: HttpStatus;
  error?: string;
  success: boolean;
  states: string[];
}

const initialState: GetStatesInitialState = {
  status: HttpStatus.IDLE,
  error: undefined,
  success: false,
  states: [],
};

export const getStates = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const country = { country: '' };
  const query = qs.stringify({ country });
  const response = await Api.get<string[], string[]>(`/v1/states?${query}`);
  return response;
});

export const stateSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStates.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
      state.success = true;
      state.states = action.payload;
    });
    builder.addCase(getStates.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.error = error?.message ?? String(error);
    });
  },
});

export default stateSlice.reducer;
