import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { SignUpRepository } from '../repositories/sign_up.repo';
import { SignUpService } from '../services/sign_up.service';
import { OnboardVendorState } from '../types';
import { SignUpFormData } from '../../../schema/auth.schema';

const NAMESPACE = 'VENDOR_SIGNUP';

const initialState: OnboardVendorState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const vendorSignUp = createAsyncThunk(`${NAMESPACE}/post`, async (payload: SignUpFormData) => {
  const signupRepo = new SignUpRepository();
  const signupService = new SignUpService(signupRepo);
  const response = await signupService.signup(payload);
  return response;
});

export const vendorSignUpSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(vendorSignUp.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(vendorSignUp.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.vendorId);
      state.status = HttpStatus.DONE;
    });
    builder.addCase(vendorSignUp.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = vendorSignUpSlice.actions;

export default vendorSignUpSlice.reducer;
