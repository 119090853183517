import { createSlice } from '@reduxjs/toolkit';

import { hasNextPage, hasPreviousPage, OnboardingSteps } from '../utils';

const NAMESPACE = 'ONBOARDING_STEPS';

export interface OnboardingStepsInitialState {
  page: OnboardingSteps;
  hasPrevious: boolean;
  hasNext: boolean;
}

const initialState: OnboardingStepsInitialState = {
  page: OnboardingSteps.Business,
  hasPrevious: false,
  hasNext: true,
};

export const onBoardingStepSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
      state.hasPrevious = hasPreviousPage(action.payload);
      state.hasNext = hasNextPage(action.payload);
    },
    previousPage: (state, action) => {},
    reset: (state, action) => {},
  },
});

export const { changePage, reset } = onBoardingStepSlice.actions;
export default onBoardingStepSlice.reducer;
