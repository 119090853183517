import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ReactivatePromoPayload, ReactivatePromoState } from '../promotion_types';
import { PromotionRepository } from '../repositories/promotion.repo';
import { PromotionService } from '../services/promotion.service';

const NAMESPACE = 'REACTIVATE_PROMO';

const initialState: ReactivatePromoState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const reactivatePromo = createAsyncThunk(`${NAMESPACE}/promo`, async (payload: ReactivatePromoPayload) => {
  const promotionRepo = new PromotionRepository();
  const promotionService = new PromotionService(promotionRepo);
  const response = await promotionService.reactivatePromo(payload);
  return response;
});

const reactivatePromoSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetPromoStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reactivatePromo.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(reactivatePromo.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(reactivatePromo.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetPromoStatus } = reactivatePromoSlice.actions;

export default reactivatePromoSlice.reducer;
