import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ChangePasswordPayload, ChangePasswordState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'CHANGE_PASSWORD';

const initialState: ChangePasswordState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const changePassword = createAsyncThunk(`${NAMESPACE}/password`, async (payload: ChangePasswordPayload) => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.changePassword(payload);
  return response;
});

const changePasswordSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetChangePasswordStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(changePassword.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetChangePasswordStatus } = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
