import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { UpdatePromoPayload, UpdatePromoState } from '../promotion_types';
import { PromotionRepository } from '../repositories/promotion.repo';
import { PromotionService } from '../services/promotion.service';

const NAMESPACE = 'UPDATE_PROMO';

const initialState: UpdatePromoState = {
  status: HttpStatus.IDLE,
  message: undefined,
  promoStatus: true,
};

export const updatePromo = createAsyncThunk(`${NAMESPACE}/promo`, async (payload: UpdatePromoPayload) => {
  const promotionRepo = new PromotionRepository();
  const promotionService = new PromotionService(promotionRepo);
  const response = await promotionService.updatePromo(payload);
  return response;
});

const updatePromoSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    togglePromoStatus: (state, action) => {
      state.promoStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePromo.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(updatePromo.fulfilled, (state, payload) => {
      state.status = HttpStatus.DONE;
      state.promoStatus = false;
    });
    builder.addCase(updatePromo.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { togglePromoStatus } = updatePromoSlice.actions;

export default updatePromoSlice.reducer;
