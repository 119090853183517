import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetReviewState } from '../reviews_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { ReviewRepository } from '../repositories/review.repo';
import { ReviewService } from '../services/review.service';

const NAMESPACE = 'GET_REVIEWS';

const initialState: GetReviewState = {
  status: HttpStatus.IDLE,
  message: undefined,
  reviews: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const getReview = createAsyncThunk(`${NAMESPACE}/review`, async (arg?: PaginationPayload) => {
  const reviewRepo = new ReviewRepository();
  const reviewService = new ReviewService(reviewRepo);
  const response = await reviewService.getReview(arg);
  return response;
});

export const getReviewSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReview.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getReview.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.reviews = payload.reviews;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getReview.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getReviewSlice.reducer;
