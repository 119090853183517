import { ForgotPasswordRepository } from '../repository/forgot_password.repo';
import {
  RequestResetPasswordPayload,
  RequestResetPasswordResponsePayload,
  ResetPasswordPayload,
  ResetPasswordResponsePayload,
  VerifyResetPasswordOtpPayload,
  VerifyResetPasswordOtpResponsePayload,
} from '../types';

export class ForgotPasswordService {
  constructor(private readonly forgotPasswordRepo: ForgotPasswordRepository) {}

  async requestResetPassword(payload: RequestResetPasswordPayload): Promise<RequestResetPasswordResponsePayload> {
    const response = await this.forgotPasswordRepo.requestResetPassword(payload);
    return response;
  }

  async verifyResetPasswordOtp(payload: VerifyResetPasswordOtpPayload): Promise<VerifyResetPasswordOtpResponsePayload> {
    const response = await this.forgotPasswordRepo.verifyResetPasswordOtp(payload);
    return response;
  }

  async resetPassword(payload: ResetPasswordPayload): Promise<ResetPasswordResponsePayload> {
    const response = await this.forgotPasswordRepo.resetPassword(payload);
    return response;
  }
}
