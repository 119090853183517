import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  CreateProductPayload,
  CreateProductResponsePayload,
  DeleteProductResponsePayload,
  GetProductDetailsPayload,
  GetProductDetailsResponsePayloadObject,
  GetProductsResponsePayload,
  GetVendorCommissionResponsePayload,
  ProductOutOfStockRequestPayload,
  ProductOutOfStockResponsePayload,
  TotalNumberOfProductsResponsePayload,
  UpdateProductPayload,
  UpdateProductResponsePayload,
  UpdateProductStatusPayload,
} from '../product_types';

export class ProductsRepository {
  async createProduct(payload: CreateProductPayload): Promise<CreateProductResponsePayload> {
    const response = await Api.post<CreateProductResponsePayload, CreateProductResponsePayload>(
      'v1/product/create-product',
      payload
    );
    return response;
  }

  async deleteProduct(payload: GetProductDetailsPayload): Promise<DeleteProductResponsePayload> {
    const response = await Api.put<DeleteProductResponsePayload, DeleteProductResponsePayload>(
      'v1/product/delete-product',
      payload
    );
    return response;
  }

  async getProductDetails(payload: GetProductDetailsPayload): Promise<GetProductDetailsResponsePayloadObject> {
    const response = await Api.get<GetProductDetailsResponsePayloadObject, GetProductDetailsResponsePayloadObject>(
      'v1/product/product-details',
      { params: payload }
    );
    return response;
  }

  async getListOfProducts(arg?: PaginationPayload): Promise<GetProductsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetProductsResponsePayload, GetProductsResponsePayload>('/v1/product', {
      params,
    });
    return response;
  }

  async getTotalNumberOfProduct(): Promise<TotalNumberOfProductsResponsePayload> {
    const response = await Api.get<TotalNumberOfProductsResponsePayload, TotalNumberOfProductsResponsePayload>(
      'v1/product/total-products'
    );
    return response;
  }

  async updateProduct(payload: UpdateProductPayload): Promise<UpdateProductResponsePayload> {
    const response = await Api.put<UpdateProductResponsePayload, UpdateProductResponsePayload>(
      'v1/product/update',
      payload
    );
    return response;
  }

  async updateProductStockAlert(payload: ProductOutOfStockRequestPayload): Promise<ProductOutOfStockResponsePayload> {
    const reqPayload = { lowStock: payload.lowStock };
    const response = await Api.put<ProductOutOfStockResponsePayload, ProductOutOfStockResponsePayload>(
      `v1/product/${payload.productId}/low-stock`,
      reqPayload
    );
    return response;
  }

  async updateProductStatus(payload: UpdateProductStatusPayload): Promise<GetProductDetailsResponsePayloadObject> {
    const reqPayload = { status: payload.status };
    const response = await Api.put<GetProductDetailsResponsePayloadObject, GetProductDetailsResponsePayloadObject>(
      `v1/product/${payload.productId}/status`,
      reqPayload
    );
    return response;
  }

  async getVendorCommission(): Promise<GetVendorCommissionResponsePayload> {
    const response = await Api.get<GetVendorCommissionResponsePayload, GetVendorCommissionResponsePayload>(
      '/v1/admin-commission/vendor-commission'
    );
    return response;
  }
}
