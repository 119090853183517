import { HttpStatus } from '../../commons';

export interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordResponsePayload {
  changedPassword: true;
}

export interface ChangePasswordState {
  status: HttpStatus;
  message?: string;
  response?: ChangePasswordResponsePayload;
}

export interface AddPaymentDetailsPayload {
  bankName: string;
  bankCode: string;
  accountNumber: string;
  accountName: string;
}

export interface AddPaymentDetailsResponsePayload {
  vendorPaymentDetail: string;
}

export interface AddPaymentDetailsState {
  status: HttpStatus;
  message?: string;
  response?: AddPaymentDetailsResponsePayload;
}

export interface UserDetailsResponsePayload {
  vendorId: string;
  proofOfAddress: Array<string>;
  isBusinessRegistered: string;
  phone: string;
  email: string;
  name: string;
  address: string;
  type: string;
  status: VendorStatus;
  logo: string;
  entityStatus: { status: string; at: string };
  bankName: string;
  accountName: string;
  accountNumber: string;
}

export interface UserDetailsState {
  status: HttpStatus;
  message?: string;
  response: UserDetailsResponsePayload;
}

export interface GetBanksResponsePayload {
  name: string;
  slug: string;
  code: string;
}

export interface GetBanksState {
  status: HttpStatus;
  message?: string;
  listOfBanks: GetBanksResponsePayload[];
}

export interface UpdateNotificationPayload {
  pushNotification: boolean;
  emailNotification: boolean;
  pickUpStatus: boolean;
  dropOffStatus: boolean;
  smsNotification: boolean;
}

export interface UpdateNotificationResponsePayload {
  updatedNotification: true;
}

export interface UpdateNotificationState {
  status: HttpStatus;
  message?: string;
  pushNotification: boolean;
  emailNotification: boolean;
  pickUpStatus: boolean;
  dropOffStatus: boolean;
  smsNotification: boolean;
  updateNotificationStatus: HttpStatus;
}

export interface GetNotificationResponsePayload {
  pushNotification: boolean;
  emailNotification: boolean;
  pickUpStatus: boolean;
  dropOffStatus: boolean;
  smsNotification: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface VerifyBankDetailsPayload {
  bankCode: string;
  accountNumber: string;
}

export interface VerifyBankDetailsResponsePayload {
  account_number: string;
  account_name: string;
  bank_id: number;
}

export interface VerifyBankDetailsState {
  status: HttpStatus;
  message?: string;
  response?: VerifyBankDetailsResponsePayload;
}

export interface GetVendorBankDetailsResponse {
  vendorId: string;
  bankName: string;
  bankCode: string;
  accountNumber: string;
  accountName: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetVendorBankDetailsState {
  status: HttpStatus;
  message?: string;
  response: GetVendorBankDetailsResponse | [];
}

export enum VendorStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED',
}
