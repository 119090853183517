import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import { GetPendingAdvertsResponsePayload, TotalNumberOfAdvertsResponsePayload } from '../adverts_types';

export class AdvertsRepository {
  async getAmountPerDay(): Promise<string | number> {
    const response = await Api.get<string, number>('/v1/advert/price');
    return response;
  }

  async getListOfActiveAdverts(arg?: PaginationPayload): Promise<GetPendingAdvertsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetPendingAdvertsResponsePayload, GetPendingAdvertsResponsePayload>(
      '/v1/advert/active-advert',
      {
        params,
      }
    );
    return response;
  }

  async getListOfExpiredAdverts(arg?: PaginationPayload): Promise<GetPendingAdvertsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetPendingAdvertsResponsePayload, GetPendingAdvertsResponsePayload>(
      '/v1/advert/expired-advert',
      {
        params,
      }
    );
    return response;
  }

  async getListOfPendingAdverts(arg?: PaginationPayload): Promise<GetPendingAdvertsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetPendingAdvertsResponsePayload, GetPendingAdvertsResponsePayload>(
      '/v1/advert/pending-adverts',
      {
        params,
      }
    );
    return response;
  }

  async getTotalNumberOfAdvert(): Promise<TotalNumberOfAdvertsResponsePayload> {
    const response = await Api.get<TotalNumberOfAdvertsResponsePayload, TotalNumberOfAdvertsResponsePayload>(
      'v1/advert/total-advert'
    );
    return response;
  }
}
