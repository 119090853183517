import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { OngoingOrderState } from '../order_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'ONGOING_ORDER';

const initialState: OngoingOrderState = {
  status: HttpStatus.IDLE,
  message: undefined,
  orders: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const ongoingOrder = createAsyncThunk(`${NAMESPACE}/ongoing`, async (arg?: PaginationPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.getOngoingOrders(arg);
  return response;
});

export const OngoingOrderSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ongoingOrder.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(ongoingOrder.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.orders = payload.orders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(ongoingOrder.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default OngoingOrderSlice.reducer;
