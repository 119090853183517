import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ListOfProductsState } from '../product_types';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'LIST_OF_PRODUCTS';

const initialState: ListOfProductsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  products: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const listOfProduct = createAsyncThunk(`${NAMESPACE}/products`, async (arg?: PaginationPayload) => {
  const productsRepo = new ProductsRepository();
  const productsService = new ProductsService(productsRepo);
  const response = await productsService.getListOfProducts(arg);
  return response;
});

const listOfProductSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listOfProduct.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(listOfProduct.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.products = payload.products;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(listOfProduct.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default listOfProductSlice.reducer;
