import { Api } from '../../../utils';
import { OnboardVendorResponsePayload, OnboardingFormDataInitialState, VerifyOTPResponsePayload } from '../types';
import { VENDOR_FORM_DATA_STORAGE_KEY } from '../utils/constant';
import { SignUpFormData } from '../../../schema/auth.schema';

export class SignUpRepository {
  async onboardVendor(): Promise<OnboardVendorResponsePayload> {
    const d = window.sessionStorage.getItem(VENDOR_FORM_DATA_STORAGE_KEY);
    if (!d) {
      return Promise.reject(new Error('Invalid data'));
    }
    const vendor: OnboardingFormDataInitialState = JSON.parse(d);
    const response = await Api.post<OnboardVendorResponsePayload, OnboardVendorResponsePayload>('/v1/vendor', vendor);
    window.localStorage.setItem('email', vendor.email);
    return response;
  }

  async signup(payload: SignUpFormData): Promise<OnboardVendorResponsePayload> {
    window.localStorage.setItem('email', payload.email);
    const response = await Api.post<OnboardVendorResponsePayload, OnboardVendorResponsePayload>('/v1/vendor', payload);
    return response;
  }

  async verifySignupOtp(otp: string): Promise<VerifyOTPResponsePayload> {
    const email = window.localStorage.getItem('email');
    const payload = { otp, email };
    const response = await Api.post<VerifyOTPResponsePayload, VerifyOTPResponsePayload>(
      '/v1/vendor/verify-otp',
      payload
    );
    return response;
  }
}
