import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';
import { HttpStatus } from '../../../commons';
import { GetVendorCommissionInitialState } from '../product_types';

const NAMESPACE = 'GET_COMMISSION';

const initialState: GetVendorCommissionInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getCommission = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const productsRepo = new ProductsRepository();
  const productsService = new ProductsService(productsRepo);
  const response = await productsService.getVendorCommission();
  return response;
});

const getCommissionSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCommission.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getCommission.fulfilled, (state, action) => {
      state.status = HttpStatus.DONE;
      state.response = action.payload;
    });
    builder.addCase(getCommission.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getCommissionSlice.reducer;
