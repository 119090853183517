export const authRoutes = {
  signIn: '/sign-in',
  signInOTP: '/sign-in-otp',
  signUp: '/sign-up',
  signUpOTP: '/sign-up-otp',
  requestResetPassword: '/request-reset-password',
  requestResetPasswordOTP: '/request-reset-password-otp',
  resetPassword: '/reset-password',
  logoUpload: '/logo-upload',
  proofOfAddressUpload: '/upload-proof-of-address',
} as const;

export const sidebarRoutes = {
  dashboard: '/dashboard',
  analytics: '/dashboard/analytics',
  products: '/dashboard/products',
  addProduct: '/dashboard/products/add-new',
  viewProduct: '/dashboard/products/:id',
  editProduct: '/dashboard/products/edit/:id',
  orders: '/dashboard/orders',
  viewOrder: '/dashboard/orders/:id',
  promo: '/dashboard/promo',
  addPromo: '/dashboard/promo/add-new',
  reactivatePromo: '/dashboard/promo/reactivate/:id',
  adverts: '/dashboard/adverts',
  addAdvert: '/dashboard/adverts/add-new',
  accounting: '/dashboard/accounting',
  review: '/dashboard/review',
  settings: '/dashboard/settings',
  help: '/dashboard/help',
} as const;

export type AuthRoutes = typeof authRoutes[keyof typeof authRoutes];
export type SidebarRoutes = typeof sidebarRoutes[keyof typeof sidebarRoutes];
