import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { TotalPlacedOrderState, TotalPlacedOrderWithDatePayload } from '../order_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'TOTAL_PLACED_ORDERS';

const initialState: TotalPlacedOrderState = {
  status: HttpStatus.IDLE,
  message: undefined,
  pendingOrders: { totalPlacedOrders: 0 },
  totalOrdersWithinDateRange: 0,
};

export const totalPlacedOrders = createAsyncThunk(`${NAMESPACE}/placedOrders`, async () => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.getTotalPlacedOrders();
  return response.totalPlacedOrders;
});

export const totalOrdersWithinDateRange = createAsyncThunk(
  `${NAMESPACE}/totalOrdersWithMonthRange`,
  async (payload: TotalPlacedOrderWithDatePayload) => {
    const orderRepo = new OrderRepository();
    const orderService = new OrderService(orderRepo);
    const response = await orderService.getTotalOrdersWithinDateRange(payload);
    return response.totalOrders;
  }
);

const totalPlacedOrdersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalPlacedOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalPlacedOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.pendingOrders!.totalPlacedOrders = payload;
    });
    builder.addCase(totalPlacedOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
    builder.addCase(totalOrdersWithinDateRange.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalOrdersWithinDateRange.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.totalOrdersWithinDateRange = payload;
    });
    builder.addCase(totalOrdersWithinDateRange.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalPlacedOrdersSlice.reducer;
