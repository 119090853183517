import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetPromoItemDetailsPayload, GetPromoDetailsState } from '../promotion_types';
import { PromotionRepository } from '../repositories/promotion.repo';
import { PromotionService } from '../services/promotion.service';

const NAMESPACE = 'GET_PROMO_DETAILS';

const initialState: GetPromoDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const getPromoDetails = createAsyncThunk(`${NAMESPACE}/promo`, async (payload: GetPromoItemDetailsPayload) => {
  const promotionRepo = new PromotionRepository();
  const promotionService = new PromotionService(promotionRepo);
  const response = await promotionService.getPromoDetails(payload);
  return response;
});

export const promoDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPromoDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getPromoDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getPromoDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = promoDetailsSlice.actions;

export default promoDetailsSlice.reducer;
