import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../components/utils';

const initialState = {
  page: PAGES.pending,
};

export const orderSlice = createSlice({
  name: 'orderPage',
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = orderSlice.actions;

export default orderSlice.reducer;
