import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { VerifySignUpOtpState } from '../../signUp/types';
import { LoginRepository } from '../repositories/login.repo';
import { LoginService } from '../services/login.service';

const NAMESPACE = 'LOGIN_OTP';

const initialState: VerifySignUpOtpState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const verifyLoginOtp = createAsyncThunk(`${NAMESPACE}/loginOtp`, async (otp: string) => {
  const loginRepo = new LoginRepository();
  const loginService = new LoginService(loginRepo);
  const response = await loginService.verifyUserOTP(otp);
  return response;
});

const loginOtpSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetVerifyOtp: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(verifyLoginOtp.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(verifyLoginOtp.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.vendorId);
      state.status = HttpStatus.DONE;
    });
    builder.addCase(verifyLoginOtp.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetVerifyOtp } = loginOtpSlice.actions;

export default loginOtpSlice.reducer;
