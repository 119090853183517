import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetRatingState } from '../reviews_types';
import { ReviewRepository } from '../repositories/review.repo';
import { ReviewService } from '../services/review.service';

const NAMESPACE = 'GET_RATINGS';

const initialState: GetRatingState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: {
    speed: 0,
    service: 0,
    taste: 0,
    totalReviews: 0,
    totalReviewAverage: 0,
  },
};

export const getRating = createAsyncThunk(`${NAMESPACE}/rating`, async () => {
  const reviewRepo = new ReviewRepository();
  const reviewService = new ReviewService(reviewRepo);
  const response = await reviewService.getRating();
  return response;
});

export const getRatingSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRating.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getRating.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(getRating.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getRatingSlice.reducer;
