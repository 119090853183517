import { PaginationPayload } from '../../../commons/types/pagination.type';
import { GetPendingAdvertsResponsePayload, TotalNumberOfAdvertsResponsePayload } from '../adverts_types';
import { AdvertsRepository } from '../repositories/adverts.repo';

export class AdvertsService {
  constructor(private readonly advertsRepo: AdvertsRepository) {}

  async getAmountPerDay(): Promise<string | number> {
    const getAmountPerDayResponse = await this.advertsRepo.getAmountPerDay();
    return getAmountPerDayResponse;
  }

  async getListOfActiveAdverts(arg?: PaginationPayload): Promise<GetPendingAdvertsResponsePayload> {
    const getListOfActiveAdvertsResponse = await this.advertsRepo.getListOfActiveAdverts(arg);
    return getListOfActiveAdvertsResponse;
  }

  async getListOfExpiredAdverts(arg?: PaginationPayload): Promise<GetPendingAdvertsResponsePayload> {
    const getListOfExpiredAdvertsResponse = await this.advertsRepo.getListOfExpiredAdverts(arg);
    return getListOfExpiredAdvertsResponse;
  }

  async getListOfPendingAdverts(arg?: PaginationPayload): Promise<GetPendingAdvertsResponsePayload> {
    const getListOfPendingAdvertsResponse = await this.advertsRepo.getListOfPendingAdverts(arg);
    return getListOfPendingAdvertsResponse;
  }

  async getTotalNumberOfAdvert(): Promise<TotalNumberOfAdvertsResponsePayload> {
    const getTotalNumberOfAdvertResponse = await this.advertsRepo.getTotalNumberOfAdvert();
    return getTotalNumberOfAdvertResponse;
  }
}
