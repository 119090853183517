import { getTotalEarnedWithinDateRangePayload, GetTotalEarningsWithDateResponsePayload } from '../analytics_types';
import { AnalyticsRepository } from '../repositories/analytics.repo';

export class AnalyticsService {
  constructor(private readonly analyticsRepo: AnalyticsRepository) {}

  async getTotalEarnedWithinDateRange(
    payload: getTotalEarnedWithinDateRangePayload
  ): Promise<GetTotalEarningsWithDateResponsePayload> {
    const getTotalEarnedWithinDateRangeResponse = await this.analyticsRepo.getTotalEarnedWithinDateRange(payload);
    return getTotalEarnedWithinDateRangeResponse;
  }
}
