import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetTotalEarningsWithDateState, getTotalEarnedWithinDateRangePayload } from '../analytics_types';
import { AnalyticsRepository } from '../repositories/analytics.repo';
import { AnalyticsService } from '../services/analytics.service';

const NAMESPACE = 'GET_TOTAL_EARNINGS_WITH_DATE';

const initialState: GetTotalEarningsWithDateState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { totalEarned: '0' },
};

export const totalEarnedWithinDateRange = createAsyncThunk(
  `${NAMESPACE}/totalEarningsWithMonthRange`,
  async (payload: getTotalEarnedWithinDateRangePayload) => {
    const analyticsRepo = new AnalyticsRepository();
    const analyticsService = new AnalyticsService(analyticsRepo);
    const response = await analyticsService.getTotalEarnedWithinDateRange(payload);
    return response;
  }
);

const totalEarningsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalEarnedWithinDateRange.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalEarnedWithinDateRange.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(totalEarnedWithinDateRange.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalEarningsSlice.reducer;
