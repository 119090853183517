import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ReplyReviewPayload, ReplyReviewState } from '../reviews_types';
import { ReviewRepository } from '../repositories/review.repo';
import { ReviewService } from '../services/review.service';

const NAMESPACE = 'REPLY_REVIEW';

const initialState: ReplyReviewState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const replyReview = createAsyncThunk(`${NAMESPACE}/review`, async (payload: ReplyReviewPayload) => {
  const reviewRepo = new ReviewRepository();
  const reviewService = new ReviewService(reviewRepo);
  const response = await reviewService.replyReview(payload);
  return response;
});

export const replyReviewSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(replyReview.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(replyReview.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(replyReview.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = replyReviewSlice.actions;

export default replyReviewSlice.reducer;
