import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetProductDetailsState, UpdateProductStatusPayload } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';

const NAMESPACE = 'UPDATE_DETAILS';

const initialState: GetProductDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const updateProductStatus = createAsyncThunk(
  `${NAMESPACE}/productStatus`,
  async (payload: UpdateProductStatusPayload) => {
    const productsRepo = new ProductsRepository();
    const productsService = new ProductsService(productsRepo);
    const response = await productsService.updateProductStatus(payload);
    return response;
  }
);

export const updateProductStatusSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetProductStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProductStatus.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(updateProductStatus.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(updateProductStatus.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetProductStatus } = updateProductStatusSlice.actions;

export default updateProductStatusSlice.reducer;
