import { LoginResponsePayload, OnboardVendorResponsePayload, VerifyOTPResponsePayload } from '../../signUp/types';
import { LoginRepository } from '../repositories/login.repo';

export class LoginService {
  constructor(private readonly loginRepo: LoginRepository) {}

  async verifyUserEmail(payload: LoginResponsePayload): Promise<OnboardVendorResponsePayload> {
    const verifyUserEmailResponse = await this.loginRepo.verifyUserEmail(payload);
    return verifyUserEmailResponse;
  }

  async verifyUserOTP(payload: string): Promise<VerifyOTPResponsePayload> {
    const verifyUserOTPResponse = await this.loginRepo.verifyUserOTP(payload);
    return verifyUserOTPResponse;
  }
}
