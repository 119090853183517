import { PaginationPayload } from '../../../commons/types/pagination.type';
import {
  GetAllPayoutRequestResponsePayload,
  GetBalanceResponsePayload,
  RequestPayoutPayload,
  RequestPayoutResponsePayload,
} from '../accounting_types';
import { AccountingRepository } from '../repositories/accounting.repo';

export class AccountingService {
  constructor(private readonly accountingRepo: AccountingRepository) {}

  async getPayoutHistory(arg?: PaginationPayload): Promise<GetAllPayoutRequestResponsePayload> {
    const getPayoutHistoryResponse = await this.accountingRepo.getPayoutHistory(arg);
    return getPayoutHistoryResponse;
  }

  async getAllPendingPayoutRequest(arg?: PaginationPayload): Promise<GetAllPayoutRequestResponsePayload> {
    const getAllPendingPayoutRequestResponse = await this.accountingRepo.getAllPendingPayoutRequest(arg);
    return getAllPendingPayoutRequestResponse;
  }

  async getBalance(): Promise<GetBalanceResponsePayload> {
    const getBalanceResponse = await this.accountingRepo.getBalance();
    return getBalanceResponse;
  }

  async requestPayout(payload: RequestPayoutPayload): Promise<RequestPayoutResponsePayload> {
    const requestPayoutResponse = await this.accountingRepo.requestPayout(payload);
    return requestPayoutResponse;
  }
}
