import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../components/utils';

const NAMESPACE = 'PROMO_PAGE';

const initialState = {
  page: PAGES.active,
};

export const promoSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changePage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { changePage } = promoSlice.actions;

export default promoSlice.reducer;
