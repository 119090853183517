import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  ConfirmOrderPayload,
  ConfirmOrderResponsePayload,
  DeclineOrderPayload,
  DeclineOrderResponsePayload,
  GetOrderItemDetailsResponsePayload,
  GetOrdersResponsePayload,
  GetTotalOrderResponsePayload,
  OngoingOrderResponsePayload,
  TotalNumberOfActiveOrderResponsePayload,
  TotalOrderPerDayPayload,
  TotalOrderPerDayResponsePayload,
  TotalOrdersWithinDateRangeResponsePayload,
  TotalPlacedOrderResponsePayload,
  TotalPlacedOrderWithDatePayload,
} from '../order_types';

export class OrderRepository {
  async getActiveOrders(arg?: PaginationPayload): Promise<GetOrdersResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetOrdersResponsePayload, GetOrdersResponsePayload>(
      'v1/order/confirmed-order-items',
      {
        params,
      }
    );
    return response;
  }

  async getTotalNumberOfActiveOrders(): Promise<TotalNumberOfActiveOrderResponsePayload> {
    const response = await Api.get<TotalNumberOfActiveOrderResponsePayload, TotalNumberOfActiveOrderResponsePayload>(
      'v1/order/total-active-order-amount'
    );
    return response;
  }

  async confirmOrder(payload: ConfirmOrderPayload): Promise<ConfirmOrderResponsePayload> {
    const response = await Api.put<ConfirmOrderResponsePayload, ConfirmOrderResponsePayload>(
      'v1/order/confirm',
      payload
    );
    return response;
  }

  async declineOrder(payload: DeclineOrderPayload): Promise<DeclineOrderResponsePayload> {
    const response = await Api.put<DeclineOrderResponsePayload, DeclineOrderResponsePayload>(
      'v1/order/decline',
      payload
    );
    return response;
  }

  async getOrderDetails(payload: ConfirmOrderPayload): Promise<GetOrderItemDetailsResponsePayload> {
    const response = await Api.get<GetOrderItemDetailsResponsePayload, GetOrderItemDetailsResponsePayload>(
      'v1/order/item/details',
      { params: payload }
    );
    return response;
  }

  async getOngoingOrders(arg?: PaginationPayload): Promise<OngoingOrderResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetOrdersResponsePayload, GetOrdersResponsePayload>('v1/order/ongoing', {
      params,
    });
    return response;
  }

  async getOrderHistory(arg?: PaginationPayload): Promise<GetOrdersResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetOrdersResponsePayload, GetOrdersResponsePayload>(
      'v1/orders/delivered-order-items',
      {
        params,
      }
    );
    return response;
  }

  async getOrderReady(payload: ConfirmOrderPayload): Promise<ConfirmOrderResponsePayload> {
    const response = await Api.put<ConfirmOrderResponsePayload, ConfirmOrderResponsePayload>('v1/order/ready', payload);
    return response;
  }

  async getPendingOrders(arg?: PaginationPayload): Promise<GetOrdersResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetOrdersResponsePayload, GetOrdersResponsePayload>('v1/orders/pending', {
      params,
    });
    return response;
  }

  async getTotalOrderPerDay(payload: TotalOrderPerDayPayload): Promise<TotalOrderPerDayResponsePayload> {
    const response = await Api.get<TotalOrderPerDayResponsePayload, TotalOrderPerDayResponsePayload>(
      'v1/order/total-orders-per-day',
      { params: payload }
    );
    return response;
  }

  async getTotalPlacedOrders(): Promise<TotalPlacedOrderResponsePayload> {
    const response = await Api.get<TotalPlacedOrderResponsePayload, TotalPlacedOrderResponsePayload>(
      'v1/order/total-placed-orders'
    );
    return response;
  }

  async getTotalOrdersWithinDateRange(
    payload: TotalPlacedOrderWithDatePayload
  ): Promise<TotalOrdersWithinDateRangeResponsePayload> {
    const response = await Api.get<
      TotalOrdersWithinDateRangeResponsePayload,
      TotalOrdersWithinDateRangeResponsePayload
    >('v1/order/total-orders', { params: payload });
    return response;
  }

  async getTotalOrders(): Promise<GetTotalOrderResponsePayload> {
    const response = await Api.get<GetTotalOrderResponsePayload, GetTotalOrderResponsePayload>(
      '/v2/order/total-vendor-orders'
    );
    return response;
  }
}
