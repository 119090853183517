const shadows = {
  none: 'none',
  0: 'none',
  50: '0px 4px 8px rgba(194, 194, 194, 0.2)',

  100: `0px 6px 12px rgba(194, 194, 194, 0.2)`,

  200: `0px 20px 13px rgba(0, 0, 0, 0.035), 
        0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 
        0px 1.85185px 3.14814px rgba(0, 0, 0, 0.0168519)`,

  300: `0px 38.5185px 25.4814px rgba(0, 0, 0, 0.0425185), 
        0px 20px 13px rgba(0, 0, 0, 0.035), 
        0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 
        0px 1.85185px 3.14814px rgba(0, 0, 0, 0.0168519)`,

  400: `0px 64.8148px 46.8518px rgba(0, 0, 0, 0.0531481), 
        0px 38.5185px 25.4814px rgba(0, 0, 0, 0.0425185), 
        0px 20px 13px rgba(0, 0, 0, 0.035), 
        0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 
        0px 1.85185px 3.14814px rgba(0, 0, 0, 0.0168519)`,

  500: `0px 100px 80px rgba(0, 0, 0, 0.07), 
        0px 64.8148px 46.8518px rgba(0, 0, 0, 0.0531481), 
        0px 38.5185px 25.4814px rgba(0, 0, 0, 0.0425185), 
        0px 20px 13px rgba(0, 0, 0, 0.035), 
        0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 
        0px 1.85185px 3.14814px rgba(0, 0, 0, 0.0168519)`,
};

export default shadows;
